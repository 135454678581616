<template>
  <section class="botonera my-2">
    <div class="flex justify-end ">
      <Button label="Descargar reporte" icon="pi pi-file" severity="success" size="small" @click="downloadReport"/>
    </div>
  </section>
  <section class="px-5 pb-5">
    <DataTable
      :value="_dataTable.rows"
      dataKey="Id"
      tableStyle="min-width: 50rem"
      size="small"
      scrollable
      :scrollHeight="scrollHeight"
    >
      <template #header>
        <div class="flex justify-between">
          <div class="grid grid-cols-1">
            <strong class="mr-2 my-auto">
              Columnas
            </strong>
            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="field"
                         @update:modelValue="onToggle"
                         placeholder="Seleccione las columnas que desea visualizar"
                         size="small"
                         class="p-inputtext-xs"
            />
          </div>
        </div>
      </template>
      <Column v-for="col of selectedColumns" :key="col.field" :field="col.field" :header="col.header"></Column>
    </DataTable>
  </section>
  <section class="paginador px-5 pb-5">
    <Paginator v-model:first="_dataTable.offset"
               :rows="_dataTable.limit"
               :totalRecords="_dataTable.count"
               :rowsPerPageOptions="_dataTable.pageOptions"
               @page="onPage($event)"
    />
  </section>
</template>
<script setup>
import InventariosServices from '../../services/index.js'
import { computed, onMounted, ref } from 'vue'
const _inventariosServices = new InventariosServices()
const scrollHeight = computed(() => `${window.innerWidth * 0.40}px`)
const _dataTable = ref({
  offset: 0,
  limit: 20,
  count: 0,
  pageOptions: [20, 50, 100],
  rows: []
})
const columns = ref([])
const selectedColumns = ref([])
onMounted(() => {
  getData()
})
const getData = async () => {
  const { data } = await _inventariosServices.stockMx({
    offset: _dataTable.value.offset,
    limit: _dataTable.value.limit,
    lotes: 1
  })
  // eslint-disable-next-line no-unused-vars
  for (const key of Object.keys(data.rows[0])) {
    columns.value.push({ field: key, header: key })
    selectedColumns.value.push({ field: key, header: key })
  }
  _dataTable.value.rows = data.rows
  _dataTable.value.count = data.count
}
const onToggle = (val) => {
  selectedColumns.value = columns.value.filter(col => val.includes(col))
}
const onPage = ({ rows }) => {
  _dataTable.value.limit = rows
  getData()
}
const downloadReport = () => {
  const url = _inventariosServices.downloadStockMx({ lotes: 1 })
  window.open(url, '_blank')
}
</script>
<style scoped>

</style>
